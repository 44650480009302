import React from "react";
import BreadCrumbSection from "~sections/portfolios/PortfolioGrid/BreadCrumb";
import { PageWrapper } from "~components/Core";
import PortfolioSection from "~sections/portfolios/PortfolioGrid/PortfolioGridThree"
import FooterFive from '~sections/agency/FooterFive'

import HeaderButton from '~sections/project/Header'

import FeatureSection from '~sections/project/Features'


const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton
  //     className="ms-auto d-none d-xs-inline-flex"
  //     btnOneText="Login"
  //     btnTwoText="Get Started For Free"
  //     mr="15px"
  //     mrLG="0"
  //   />
  // ),
}

export default function portFolioMasonaryThreeNoGap() {
  return (
    <PageWrapper innerPage={true}>
      <BreadCrumbSection title="Portfolio" text="Bring your idea into finger tips.." />
      {/* <FeatureSection /> */}
      <PortfolioSection background="#f3f4f6" containerFluid={true} gutters={true} />
      <FooterFive />
    </PageWrapper>
  )
}
